.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  background-image: url('pictures/Background.png'); /* Update with the path to the generated background image */
  background-size: cover; /* Cover the entire background */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Do not repeat the background image */
  position: relative; /* Relative positioning for overlay */
  overflow: hidden; /* Prevents content overflow */
}

/* Overlay to darken the background */
.home-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 1; /* Place overlay above the background */
}

/* Content area styled as a card */
.home-content {
  position: relative; /* Ensure content is above the overlay */
  z-index: 2; /* Place content above overlay */
  background-color: rgba(255, 255, 255, 0.8); /* Light semi-transparent background for the content box */
  padding: 40px; /* Padding for content */
  border-radius: 10px; /* Rounded corners */
  text-align: center; /* Center text alignment */
  max-width: 600px; /* Limit width for readability */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Add subtle shadow */
}

.home-content h1 {
  font-size: 2.5rem; /* Font size for the title */
  margin-bottom: 20px; /* Space below the title */
  color: #333; /* Darker text color for readability */
}

.home-content p {
  font-size: 1.2rem; /* Font size for the description */
  margin-bottom: 30px; /* Space below the description */
  color: #555; /* Darker text color for readability */
}

.home-links {
  display: flex; /* Use flexbox for alignment */
  justify-content: space-around; /* Space out the buttons */
}

.btn {
  padding: 10px 20px; /* Padding for buttons */
  border: none; /* Remove border */
  border-radius: 5px; /* Rounded corners */
  margin: 10px;
  cursor: pointer; /* Pointer cursor on hover */
  text-decoration: none; /* Remove text underline */
  font-size: 1rem; /* Font size for buttons */
  color: white; /* White text color */
}

.btn-register {
  background-color: #28a745; /* Green background for register button */
}

.btn-login {
  background-color: #007bff; /* Blue background for login button */
}

.btn-register:hover,
.btn-login:hover {
  opacity: 0.9; /* Slight transparency effect on hover */
}
