/* Vocabulary Container */
.vocabulary-container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  text-align: center; /* Center align content for a cleaner look */
}

/* Title */
.vocabulary-title {
  text-align: center;
  font-size: 2em;
  color: #333;
  margin-bottom: 20px;
}

/* Top Bar */
.top-bar {
  margin-bottom: 20px;
  text-align: center;
}

/* Search and Button Container */
.search-controls {
  display: flex;
  flex-direction: column; /* Stack vertically on mobile */
  align-items: center; /* Center-align contents */
  margin-bottom: 20px;
}

.search-controls > * {
  margin: 10px 0; /* Spacing between elements */
}

.search-bar-container {
  width: 100%;
  max-width: 200px; /* Limit width for mobile */
  margin-bottom: 15px;
}

.search-bar-container input {
  font-size: 1rem;
  padding: 10px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
}

/* Vocabulary List Styles */
.vocabulary-list {
  list-style-type: none;
  padding: 0;
}

/* Table Styles */
.vocabulary-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.vocabulary-table th,
.vocabulary-table td {
  padding: 15px 20px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
  vertical-align: middle;
  font-size: 1em; /* Consistent font size */
}

.vocabulary-table th {
  background-color: #007bff;
  color: white;
  font-weight: 600;
}

.vocabulary-table tr:hover td {
  background-color: #f1f1f1;
}

/* Button Styles */
button,
.edit-button,
.delete-button,
.submit-button,
.cancel-button,
.toggle-button-add,
.toggle-translation-button {
  padding: 10px 15px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s, color 0.3s;
  color: white;
  background-color: #007bff;
}

button:hover,
.edit-button:hover,
.delete-button:hover,
.submit-button:hover,
.cancel-button:hover,
.toggle-button-add:hover,
.toggle-translation-button:hover,
.toggle-word-button:hover {
  background-color: #0056b3;
}

/* Specific Button Styles */
.delete-button {
  background-color: #dc3545;
}

.delete-button:hover {
  background-color: #a71d2a;
}

.edit-button {
  background-color: #ffc107;
}

.edit-button:hover {
  background-color: #e0a800;
}

.toggle-button-add {
  background-color: #1c9b3a;
  font-size: 1rem;
  width: auto;
}

.toggle-button-add:hover {
  background-color: #045720;
}

.toggle-translation-button {
  background-color: #000;
}

.toggle-word-button,
.toggle-translation-button {
  padding: 5px;
  margin: 5px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s, color 0.3s;
  color: white;
  background-color: #000305;
}

.toggle-translation-button:hover,
.toggle-word-button:hover {
  background-color: #35383c;
}

/* Form and Modal Styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.vocabulary-form {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 15px;
}

.form-label {
  display: block;
  font-size: 1em;
  color: #333;
  margin-bottom: 5px;
}

.form-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Cancel Button */
.cancel-button {
  background-color: #6c757d;
}

.cancel-button:hover {
  background-color: #5a6268;
}

/* Close Button */
.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
}

.close-button:hover {
  color: #ff0000;
  transform: rotate(90deg);
}

/* Checkbox Container */
.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.checkbox-input {
  position: absolute;
  opacity: 0;
}

.custom-checkbox {
  width: 20px;
  height: 20px;
  border: 2px solid #ddd;
  border-radius: 4px;
  position: relative;
  transition: all 0.3s ease;
  margin-right: 10px;
  background-color: #fff;
  display: inline-block;
  box-sizing: border-box;
}

.custom-checkbox::after {
  content: "";
  position: absolute;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(40deg);
  top: 1px;
  left: 5px;
  opacity: 0;
  transition: all 0.3s ease;
}

.checkbox-label {
  font-size: 16px;
  color: #333;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.checkbox-container:hover .custom-checkbox {
  border-color: #0056b3;
}

.checkbox-input:checked + .checkbox-label .custom-checkbox {
  background-color: currentColor;
  border-color: currentColor;
}

.checkbox-input:checked + .checkbox-label .custom-checkbox::after {
  opacity: 1;
}

/* Dynamic Colors Based on Option Value */
.checkbox-label.masculine .custom-checkbox,
.checkbox-label.masculine {
  color: #007bff;
  border-color: #007bff;
}

.checkbox-label.feminine .custom-checkbox,
.checkbox-label.feminine {
  color: #b81717;
  border-color: #b81717;
}

.checkbox-label.neuter .custom-checkbox,
.checkbox-label.neuter {
  color: #28a745;
  border-color: #28a745;
}

.checkbox-label.plural .custom-checkbox,
.checkbox-label.plural {
  color: #6f42c1;
  border-color: #6f42c1;
}

/* Responsive Design */
@media (max-width: 768px) {
  .modal-content {
    width: 90%;
  }

  .vocabulary-table th,
  .vocabulary-table td {
    padding: 10px;
    font-size: 0.9em;
  }

  .form-input,
  .search-bar-container input {
    width: 100%;
  }

  .filter-container,
  .search-bar-container {
    flex-direction: column;
    align-items: stretch;
  }

  .search-bar-container input,
  .search-bar-container .toggle-translation-button {
    margin: 10px 0;
    width: 100%;
  }

  .checkbox-label {
    font-size: 14px;
  }

  .vocabulary-table tr {
    display: flex;
    flex-direction: column; /* Stack rows vertically for mobile */
    margin-bottom: 10px; /* Add spacing between rows */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Add shadow for card-like appearance */
  }

  .vocabulary-table td {
    text-align: center; /* Center-align text */
  }

  .edit-button,
  .delete-button {
    width: 45%; /* Make action buttons smaller */
    margin: 5px; /* Add spacing */
  }
}
