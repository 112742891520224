/* FolderList.css */
.folder-page {
    background-image: url("pictures/FolderBackground.png"); /* Update the path to your background image */
    background-size: cover;
    background-position: center;
    height: 100vh; /* Occupy the full viewport height */
    width: 100%; /* Occupy full viewport width */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    overflow: hidden; /* Prevent scrolling */
  }
  
  .folder-container {
    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white */
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    max-width: 800px;
    width: 100%;
    padding: 40px;
    height: 100vh; /* Full viewport height */
  }
  
  .folder-card {
    margin-bottom: 15px;
    margin-right: 10px;
    margin-left: 10px;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .folder-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  }
  
  .folder-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    background-color: #f9f9f9;
    border-radius: 10px;
  }
  
  .feedback-button {
    position: fixed;
    bottom: 16px;
    right: 16px;
    background-color: #ff5722;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
  }
  
  .feedback-button:hover {
    transform: scale(1.1);
  }
  