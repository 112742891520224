/* Login.css */

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  background-image: url("pictures/Background.png"); /* Update with the path to the generated background image */
  background-size: cover; /* Cover the entire background */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Do not repeat the background image */
  position: relative; /* Relative positioning for overlay */
  overflow: hidden; /* Prevents content overflow */
}

body,
html {
  margin: 0;
  padding: 0;
  font-family: "Arial", sans-serif;
  height: 100%;
}

.login-content {
  background: rgba(255, 255, 255, 0.9);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  width: 300px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.8rem;
  color: #333;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #555;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

input:focus {
  border-color: #007bff;
  outline: none;
}

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-content {
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px; /* Adjust the margin for spacing between content and footer */
}

.contact-footer {
  margin-top: auto;
  padding: 15px;
  text-align: center;
  background-color: #f1f1f1;
  width: 100%;
  position: absolute; /* Fixed positioning at the bottom */
  bottom: 0;
  left: 0;
}

.contact-footer p {
  margin: 0;
  font-size: 14px;
  color: #555;
}

.contact-footer a {
  color: #007bff;
  text-decoration: none;
}

.contact-footer a:hover {
  text-decoration: underline;
}
